import { usePartner } from 'hooks/usePartner'
import { isMamba } from 'common/constants'
import React, { useEffect, useMemo } from 'react'
import { VipDefaultSvg } from 'components/presentational/svg/VipDefaultSvg'
import { VipActiveSvg } from 'components/presentational/svg/VipActiveSvg'
import { VipNewYearActiveSvg } from 'components/presentational/svg/VipNewYearActiveSvg'
import { VipNewYearDefaultSvg } from 'components/presentational/svg/VipNewYearDefaultSvg'
import { useDispatch } from 'react-redux'
import { updateShellAction } from 'actions/pwa/updateShellAction'

const december = 11 as const
const january = 0 as const

export const useIsNewYear = (): { isNewYear: boolean } => {
  const { partnerId } = usePartner()

  const now = new Date()

  return {
    isNewYear:
      isMamba(partnerId) &&
      (now.getMonth() === december ||
        (now.getMonth() === january && now.getDate() <= 15)),
  }
}

export const useVipNewYearOrNotIcon = ({
  id,
  size = 50,
}: {
  id?: string
  size?: number
}) => {
  const { isNewYear } = useIsNewYear()

  /**
   * TODO JSX не должно быть в хуках.
   */
  if (isNewYear) {
    return {
      defaultIcon: <VipNewYearDefaultSvg height={size} width={size} id={id} />,
      activeIcon: <VipNewYearActiveSvg height={size} width={size} id={id} />,
    }
  }

  return {
    defaultIcon: <VipDefaultSvg size={size} />,
    activeIcon: <VipActiveSvg size={size} id={id} />,
  }
}
