import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isMamba } from 'common/constants'

export enum ThirdPartyPlace {
  logo,
  secondaryBanner,
  downloadAppBanner,
  registrationLink,
  filterIcon,
  bottomRegisterBanner,
  joinBanner,
}

const allowedUrls = [
  '/landing/russia/moscow-and-moscow-region/moscow-4400',
  '/landing/russia/saint-petersburg-and-region/saint-petersburg-4962',
  '/landing/russia/novosibirskaya-obl/novosibirsk-4549',
  '/landing/russia/sverdlovskaya-obl/ekaterinburg-5106',
  '/landing/russia/tatarstan/kazan-5269',
]

/**
 * ТЗ в задаче:
 * https://youtrack.mamba.ru/issue/M-6919
 * @param place
 */
export const useThirdPartyUrl = (place: ThirdPartyPlace) => {
  const { pathname, partnerId, authorized } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
      systemReducer: { partnerId },
      authorizationReducer: { authorized },
    }) => ({ pathname, partnerId, authorized })
  )

  if (
    authorized ||
    !isMamba(partnerId) ||
    !allowedUrls.some((path) => pathname.endsWith(path))
  ) {
    return null
  }

  switch (place) {
    case ThirdPartyPlace.logo:
      return 'https://click.mamba.ru/uhNt/eyxu3cuh'
    case ThirdPartyPlace.secondaryBanner:
      return 'https://click.mamba.ru/uhNt/meeiym9d'
    case ThirdPartyPlace.downloadAppBanner:
      return 'https://click.mamba.ru/uhNt/h6dh8mbb'
    case ThirdPartyPlace.registrationLink:
      return 'https://click.mamba.ru/uhNt/sxwjgzbo'
    case ThirdPartyPlace.filterIcon:
      return 'https://click.mamba.ru/uhNt/0mwy48ga'
    case ThirdPartyPlace.bottomRegisterBanner:
      return 'https://click.mamba.ru/uhNt/u6uxuzzn'
    case ThirdPartyPlace.joinBanner:
      return 'https://click.mamba.ru/uhNt/ccxuygmo'
  }
}
