import { MambaLogoSvg } from 'components/presentational/svg/MambaLogoSvg'
import { LoveMailruSvg } from 'components/presentational/svg/LoveMailruSvg'
import wamba from 'components/presentational/Logo/wamba.svg'
import yonja from 'components/presentational/Logo/yonja.svg'
import React, { FC } from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { settingPartner } from 'common/constants'
import { media, sizes } from 'components/presentational'
import styled, { css } from 'styled-components'
import { MambaLogoEmptySvg } from 'components/presentational/svg/MambaLogoEmptySvg'
import { useIsNewYear } from 'hooks/useIsNewYear'
import { MambaLogoNewYear2024Svg } from 'components/presentational/svg/MambaLogoNewYear2024Svg'

export const LogoIndex: FC<{
  /**
   * Мелкое лого для анонимов.
   */
  alternativeLogo?: boolean
  logoLandingMailRu?: boolean
}> = ({ alternativeLogo, logoLandingMailRu = false }) => {
  const { partnerId, partner } = useShallowEqualSelector(
    ({ systemReducer: { partnerId, partner } }) => ({
      partnerId,
      partner,
    })
  )

  switch (partnerId) {
    case 3:
    case 81449119:
      return <SwitchMambaLogo alternative={alternativeLogo!} />

    case 958134831:
    case 963463432:
      return <SwitchLogo alternativeLogo={alternativeLogo} logo={wamba} />

    case 132113273:
    case 475306:
      return (
        <LogoMailru $logoLandingMailRu={logoLandingMailRu}>
          <LoveMailruSvg />
        </LogoMailru>
      )

    case 1374393173:
    case 1374393182:
      return <SwitchLogo alternativeLogo={alternativeLogo} logo={yonja} />
  }

  if (partner && partner.logoUrl) {
    return (
      <SwitchLogo alternativeLogo={alternativeLogo} logo={partner.logoUrl} />
    )
  }
  return <div style={{ height: 38 }} />
}

export const LogoMailru = styled.div<{ $logoLandingMailRu: boolean }>`
  svg {
    height: 28px;
    width: 180px;
    g g {
      fill: white;
    }
  }

  // для landing
  ${(props) =>
    props.$logoLandingMailRu &&
    `
    svg g g { fill: ${settingPartner.mailru.color}; }
    
    @media (max-width: ${sizes.phone}px) {    
      svg g g { fill: white }
    };      
  `};
`

const SwitchLogo: FC<{
  logo: string
  alternativeLogo?: boolean
}> = ({ logo, alternativeLogo }) => {
  if (alternativeLogo) {
    return <ImageMobile src={logo} alt="logo" />
  }
  return <Image src={logo} alt="logo" />
}

const SwitchMambaLogo: FC<{ alternative: boolean }> = ({ alternative }) => {
  const { isNewYear } = useIsNewYear()

  if (isNewYear) {
    if (alternative) {
      return (
        <MambaLogoVisibility>
          <MambaLogoNewYear2024Svg uid="alternative" width={160} height={49} />
        </MambaLogoVisibility>
      )
    }

    return <MambaLogoNewYear2024Svg uid="default" />
  }

  if (alternative) {
    return <StyledMambaLogoEmptySvg />
  }

  return <MambaLogoSvg fill={'#F56323'} />
}

const mobileLogoCss = css`
  display: none;

  ${media.phone`
    display: block;
    margin: 10px auto 0;
  `}
`

const sizeLogoCss = css`
  max-height: 56px;
  max-width: 100%;
`

export const Image = styled.img`
  ${sizeLogoCss}
`

export const ImageMobile = styled.img`
  ${sizeLogoCss}
  ${mobileLogoCss}
`

const StyledMambaLogoEmptySvg = styled(MambaLogoEmptySvg)`
  ${mobileLogoCss}
`

/**
 * Скрывает лого на боардинге, если это не телефон.
 * Чтобы логотипы не двоились.
 */
const MambaLogoVisibility = styled.div`
  ${mobileLogoCss}
`
