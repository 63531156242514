import React, { FC, useId } from 'react'

/**
 * Новый год 2023-2024
 * @constructor
 */
export const MambaLogoNewYear2024Svg: FC<{
  uid: string
  width?: number
  height?: number
}> = ({ uid, width = 570, height = 160 }) => {
  const id1 = `${uid}1`
  const id2 = `${uid}2`

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 570 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M175.023 54.714C166.996 54.714 158.323 56.8176 150.284 60.6488C150.061 60.7546 149.791 60.7429 149.579 60.6136C143.033 56.5591 136.088 54.996 128.754 54.996C118.601 54.996 107.918 58.0164 97.4936 61.8711C97.1998 61.9768 97 62.2589 97 62.5762V128.071C97 128.482 97.3291 128.823 97.7404 128.823H124.876C125.287 128.823 125.628 128.494 125.628 128.071V77.7835C125.628 77.4662 125.828 77.1724 126.134 77.0784C129.883 75.8679 134.724 75.7151 134.724 80.6393V128.071C134.724 128.482 135.054 128.823 135.465 128.823H162.601C163.012 128.823 163.353 128.494 163.353 128.071V77.7953C163.353 77.478 163.553 77.1841 163.87 77.0901C167.725 75.8679 172.449 75.5859 172.449 80.651V128.083C172.449 128.494 172.778 128.835 173.189 128.835H200.466C200.877 128.835 201.207 128.506 201.207 128.083V79.6991C201.207 61.9181 189.396 54.7257 175.011 54.7257"
        fill="#F56323"
      />
      <path
        d="M534.919 60.2728C527.045 60.2728 522.567 54.714 509.275 54.714C486.747 54.714 475.077 69.6393 475.077 92.7088C475.077 115.778 486.747 130.304 509.275 130.304C516.197 130.304 527.057 128.13 534.919 128.13C539.267 128.13 543.039 128.247 546.142 128.682C546.589 128.741 547 128.4 547 127.942V59.5324C547 59.0388 546.53 58.6745 546.048 58.8155C542.957 59.7322 539.22 60.2845 534.919 60.2845M518.231 110.76C518.231 111.089 518.019 111.383 517.702 111.477C516.28 111.888 514.975 111.994 513.882 111.994C507.231 111.994 506.149 105.342 506.149 93.5432C506.149 81.744 507.372 74.8102 514.023 74.8102C515.105 74.8102 516.28 75.0218 517.655 75.3508C517.996 75.4331 518.231 75.7386 518.231 76.0794V110.772V110.76Z"
        fill="#F56323"
      />
      <path
        d="M446.438 54.7136C442.09 54.7136 437.025 55.1955 431.349 57.969C430.855 58.204 430.279 57.8397 430.279 57.2991V34.4294C430.279 34.0181 429.95 33.6772 429.527 33.6772H402.25C401.839 33.6772 401.498 34.0063 401.498 34.4294V127.965C401.498 128.412 401.898 128.752 402.344 128.705C416.165 127.048 423.169 130.304 434.345 130.304C460.67 130.304 470.448 117.823 470.448 90.9456C470.448 72.765 463.115 54.7136 446.427 54.7136M434.616 111.982C433.523 111.982 432.23 111.876 430.796 111.465C430.479 111.371 430.267 111.077 430.267 110.748V76.0556C430.267 75.7148 430.502 75.4092 430.843 75.327C432.218 74.9979 433.405 74.7864 434.475 74.7864C441.126 74.7864 442.349 81.4381 442.349 93.5193C442.349 105.601 441.267 111.97 434.616 111.97"
        fill="#F56323"
      />
      <path
        d="M241.386 54.714C218.857 54.714 207.187 69.6393 207.187 92.7088C207.187 115.778 218.857 130.304 241.386 130.304C248.308 130.304 259.167 128.13 267.029 128.13C271.377 128.13 275.15 128.247 278.252 128.682C278.699 128.741 279.11 128.4 279.11 127.942V59.5324C279.11 59.0388 278.64 58.6745 278.158 58.8155C275.068 59.7322 271.33 60.2845 267.029 60.2845C259.155 60.2845 254.678 54.7257 241.386 54.7257M250.341 110.76C250.341 111.089 250.129 111.383 249.812 111.477C248.39 111.888 247.086 111.994 245.993 111.994C239.341 111.994 238.26 105.342 238.26 93.5432C238.26 81.744 239.482 74.8102 246.134 74.8102C247.215 74.8102 248.39 75.0218 249.765 75.3508C250.106 75.4331 250.341 75.7386 250.341 76.0794V110.772V110.76Z"
        fill="#F56323"
      />
      <path
        d="M366.228 54.714C358.201 54.714 349.528 56.8176 341.489 60.6488C341.266 60.7546 340.996 60.7429 340.784 60.6136C334.238 56.5591 327.293 54.996 319.959 54.996C309.806 54.996 299.123 58.0164 288.699 61.8711C288.405 61.9768 288.205 62.2589 288.205 62.5762V128.071C288.205 128.482 288.534 128.823 288.945 128.823H316.081C316.493 128.823 316.833 128.494 316.833 128.071V77.7835C316.833 77.4662 317.033 77.1724 317.339 77.0784C321.088 75.8679 325.93 75.7151 325.93 80.6393V128.071C325.93 128.482 326.259 128.823 326.67 128.823H353.806C354.217 128.823 354.558 128.494 354.558 128.071V77.7953C354.558 77.478 354.758 77.1841 355.075 77.0901C358.93 75.8679 363.654 75.5859 363.654 80.651V128.083C363.654 128.494 363.983 128.835 364.394 128.835H391.671C392.083 128.835 392.423 128.506 392.423 128.083V79.6991C392.423 61.9181 380.612 54.7257 366.228 54.7257"
        fill="#F56323"
      />
      <path
        d="M118 56.3301C115.836 82.2982 102.672 99.6104 96.3599 105.02V64.4451L99.0649 61.7401C105.557 59.5761 114.393 57.2318 118 56.3301Z"
        fill="#CC3E00"
      />
      <path
        d="M79.3535 127.651C79.3535 127.651 75.5873 122.874 80.723 120.397C85.8586 117.92 87.9129 122.52 87.7417 125.528C87.3993 128.536 83.6332 129.42 83.6332 129.42C83.6332 129.42 81.2365 129.42 79.3535 127.651Z"
        fill="#8686EA"
      />
      <path
        d="M29.5837 30.7084C29.5837 30.7084 27.7006 28.3199 30.2685 27.0814C32.8363 25.8429 33.8634 28.143 33.7778 29.6468C33.6066 31.1507 31.7236 31.593 31.7236 31.593C31.7236 31.593 30.5252 31.593 29.5837 30.7084Z"
        fill="#FCD21D"
      />
      <path
        d="M143.061 40.1305C143.464 39.3474 144.09 38.738 144.75 38.154C145.409 37.57 146.364 37.3987 147.27 37.5548C148.433 37.9101 149.104 39.1623 149.952 40.1868C151.225 41.7234 153.757 42.9605 155.204 41.8921C156.474 41.0516 156.55 39.1015 156.489 37.5927C156.3 35.9843 156.377 34.0341 157.646 33.1937C158.699 32.3675 160.295 32.6941 161.891 33.0206C163.487 33.3472 165.34 33.8729 166.267 35.3243C167.323 36.8753 166.685 38.7545 165.879 40.3207C165.742 40.7621 165.476 41.1038 165.043 41.1325C164.826 41.1469 164.481 41.0616 164.353 40.9621C163.279 40.4929 162.077 39.9242 161.445 38.8854C161.109 38.2593 161.384 37.3766 162.034 37.3335"
        stroke="#8686EA"
        strokeWidth="3.04048"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.04048 146.202C8.04048 146.202 5.18107 145.486 5 142.407C5 141.14 5 140.054 5.54321 138.967C6.08642 137.881 7.17285 136.975 8.25927 136.794C9.3457 136.613 10.4321 137.156 11.3375 137.7C12.605 138.243 13.8725 138.967 14.9589 139.51C16.0453 140.054 17.1317 140.597 18.3992 140.778C19.6667 140.959 20.9342 140.778 21.8396 140.054C23.4692 138.605 23.1071 135.889 21.8396 134.078C21.4774 133.535 21.1153 132.992 21.1153 132.449C21.1153 131.543 22.2017 131 23.1071 131C24.0124 131.181 24.7367 131.724 25.461 132.267"
        stroke="#8686EA"
        strokeWidth="3.04048"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.4026 59.5744C52.4026 59.5744 57.7172 52.6943 55.6481 47.1737L61.3197 46.9998C61.3197 46.9998 60.0701 56.6768 75.048 62.8337L76.3022 71.5265L72.4204 76.9235C72.4204 76.9235 66.9777 71.38 62.5374 73.6642C62.5374 73.6642 58.3168 60.5586 52.4026 59.579V59.5744Z"
        fill="#A4A4EF"
      />
      <path
        d="M84.8529 88.5962C84.8529 88.5962 84.1846 96.401 78.8333 102.64C78.8333 102.64 77.1625 104.311 78.0552 105.872C78.9478 107.433 80.7285 108.545 80.7285 108.545C80.7285 108.545 82.8479 109.662 84.8529 107.987C86.8579 106.311 93.77 95.6136 90.4284 85.0257C90.4284 85.0257 89.8699 83.5746 87.5308 85.0257C85.1916 86.4768 84.8575 88.5916 84.8575 88.5916L84.8529 88.5962Z"
        fill="#1A9253"
      />
      <path
        d="M50.8557 101.661C50.8557 101.661 50.7092 109.493 45.7929 116.08C45.7929 116.08 44.2365 117.861 45.2298 119.358C46.2232 120.855 48.0771 121.848 48.0771 121.848C48.0771 121.848 50.2652 122.819 52.1557 121.015C54.0463 119.211 60.226 108.07 56.1794 97.7287C56.1794 97.7287 55.5248 96.3188 53.2864 97.921C51.048 99.5231 50.8557 101.661 50.8557 101.661Z"
        fill="#1A9253"
      />
      <path
        d="M102.651 58.3754C102.651 58.3754 103.686 50.8773 114.283 49.0692C124.88 47.261 131.344 43.1229 131.344 43.1229C131.344 43.1229 134.447 40.7975 133.93 37.6939C133.93 37.6939 133.802 35.1075 130.827 36.2702C127.851 37.4329 110.406 39.7584 105.233 38.7238C100.061 37.6893 92.8233 53.1982 102.647 58.3709L102.651 58.3754Z"
        fill="#61D899"
      />
      <path
        d="M130.827 36.2745C130.827 36.2745 133.756 35.4551 133.5 37.1809C133.244 38.9066 114.027 44.8072 104.203 41.4472C94.3798 38.0873 113.766 35.1118 113.766 35.1118L109.371 38.989C109.371 38.989 123.589 38.5999 130.827 36.2745Z"
        fill="#49497C"
      />
      <path
        d="M85.3341 58.1189C85.3341 58.1189 77.7079 72.5933 62.199 74.406C46.6901 76.2188 36.4774 76.7314 24.8458 66.3907C13.2141 56.0499 12.6923 41.5709 12.9532 35.8856L13.2095 29.422C13.2095 29.422 6.22869 42.3491 10.8841 63.8043C15.5395 85.2595 30.2107 103.739 51.7254 106.587C86.8814 111.237 100.582 88.1022 100.582 63.548C100.582 63.548 101.099 53.4681 107.563 51.1381L85.3341 58.1189Z"
        fill={`url(#${id1})`}
      />
      <path
        d="M100.843 43.1227C100.843 43.1227 103.686 40.5364 106.789 41.3146C109.893 42.0927 124.111 37.4373 130.831 32.7819C133.985 30.5984 134.845 28.9367 134.104 26.149C132.895 21.5988 123.823 15.0849 119.799 17.5339C119.758 17.5568 119.716 17.5843 119.675 17.6118C115.537 20.1981 114.283 24.5148 111.184 25.2884C108.085 26.062 100.587 23.2193 93.3495 29.9392C86.1123 36.6591 91.0241 45.1918 88.1814 51.6553C88.1814 51.6553 97.744 57.858 100.848 57.858C103.951 57.858 105.498 50.1035 103.69 49.069C101.882 48.0345 99.0395 46.2263 100.848 43.1227H100.843Z"
        fill="#61D899"
      />
      <path
        d="M96.0595 28.0036C96.0595 28.0036 92.1823 27.7473 90.1132 24.9C88.0442 22.0527 85.3296 22.1855 83.7778 22.5746C83.7778 22.5746 82.9722 22.8996 83.2926 23.5771C83.613 24.2545 84.0983 24.2866 84.0983 24.2866C84.0983 24.2866 86.0392 24.6436 86.5519 26.1909C87.0645 27.7381 87.7375 32.0914 90.6671 34.5038C90.6671 34.5038 93.3404 28.7314 96.0504 27.999L96.0595 28.0036Z"
        fill="#E06157"
      />
      <path
        d="M40.8725 102.71C40.8725 102.71 39.3206 95.3446 45.2669 96.1182C51.2132 96.8919 78.6147 97.67 88.4382 86.2947C98.2618 74.9194 98.5181 84.0975 92.8327 91.8519C87.1474 99.6064 67.5003 115.893 40.8725 102.71Z"
        fill="#ACF2CE"
      />
      <path
        d="M124.367 17.2733C124.367 17.2733 121.364 16.5638 119.675 17.6075C117.986 18.6512 116.956 20.8896 119.712 20.9766C122.467 21.0635 122.641 18.9945 125.315 20.4593C127.988 21.9241 129.105 20.8026 128.762 19.7681C128.762 19.7681 126.006 17.786 124.367 17.2687V17.2733Z"
        fill="#E06157"
      />
      <path
        d="M113.766 42.4775C113.766 42.4775 117.387 42.4775 119.712 40.2802C122.038 38.083 125.787 35.8857 130.053 39.2457C130.053 39.2457 121.008 42.4775 113.766 42.4775Z"
        fill="#E06157"
      />
      <path
        d="M106.099 38.9885C106.099 38.9885 102.221 40.9706 103.86 43.8133C103.86 43.8133 100.157 40.2794 106.099 38.9885Z"
        fill="#09A14B"
      />
      <path
        d="M72.064 71.3028C72.064 71.3028 68.0998 66.304 78.4406 66.9953C78.4406 66.9953 73.4418 62.3444 82.922 61.9965C82.922 61.9965 77.7539 56.8284 86.0257 56.4805C86.0257 56.4805 78.9578 52.6903 89.1293 48.3827C89.1293 48.3827 80.8575 47.6915 89.1293 40.8022C89.1293 40.8022 93.0935 62.0011 72.0686 71.3073L72.064 71.3028Z"
        fill="#FCD21C"
      />
      <path
        d="M102.038 36.224C102.038 36.224 97.0756 36.9061 97.1763 33.2028C97.277 29.4995 98.3894 26.0846 102.34 25.1096C106.29 24.1346 108.716 26.0846 111.248 29.4995C113.779 32.9144 109.225 36.5216 102.038 36.2286V36.224Z"
        fill="#09A14B"
      />
      <path
        d="M100.797 33.0848C100.797 33.0848 99.8316 29.2808 102.084 27.761C104.336 26.2412 107.398 25.2891 109.976 30.2329C109.976 30.2329 111.427 32.3249 108.364 34.0369C105.302 35.7489 101.278 34.7968 100.793 33.0848H100.797Z"
        fill="white"
      />
      <path
        d="M103.558 30.0257C103.558 30.0257 102.267 30.1996 102.349 31.4905C102.432 32.7814 104.071 33.5596 105.279 32.699C106.488 31.8384 106.053 30.9778 106.053 30.9778C106.053 30.9778 105.622 29.2566 103.553 30.0302L103.558 30.0257Z"
        fill="#24421D"
      />
      <path
        d="M105.063 25.2881C105.063 25.2881 106.185 22.6148 109.801 25.4621C109.801 25.4621 106.098 25.636 105.063 25.2881Z"
        fill="#09A14B"
      />
      <path
        d="M38.8843 98.6441C38.8843 98.6441 40.6833 107.003 34.8423 116.799C34.8423 116.799 33.4461 119.211 35.0986 121.656C36.7511 124.1 41.9284 124.984 46.7852 118.383C51.6466 111.777 51.5917 99.1522 49.2159 94.0986C49.2159 94.0986 45.8285 90.9217 38.8843 98.6441Z"
        fill="#22CF75"
      />
      <path
        d="M69.3022 90.4277C69.3022 90.4277 68.5286 99.473 62.3213 106.715C62.3213 106.715 60.3804 108.656 61.415 110.464C62.4495 112.272 64.5186 113.567 64.5186 113.567C64.5186 113.567 66.9722 114.858 69.3022 112.922C71.6322 110.986 79.643 98.5758 75.7657 86.2987C75.7657 86.2987 75.1203 84.6187 72.4058 86.2987C69.6913 87.9786 69.3022 90.4368 69.3022 90.4368V90.4277Z"
        fill="#25D177"
      />
      <path
        d="M92.0539 31.9212C92.0539 31.9212 83.2649 35.7115 81.1959 29.5089C79.1268 23.3062 73.7847 26.9225 73.7847 26.9225C73.7847 26.9225 73.0935 27.9571 74.128 28.131C75.1626 28.3049 76.5404 27.9571 76.8838 30.0261C77.2271 32.0952 79.8134 41.2275 90.1542 40.0236C90.1542 40.0236 91.2757 39.9595 92.0493 38.4077C92.823 36.8559 94.0543 31.1934 92.0493 31.9258L92.0539 31.9212Z"
        fill="#FCD21C"
      />
      <path
        d="M88.8217 49.9759C88.8217 49.9759 88.9499 50.8823 93.3444 53.8532C97.7389 56.824 102.779 58.1195 103.296 57.2131C103.296 57.2131 104.587 56.9568 104.72 58.6367C104.852 60.3167 103.04 61.7404 102.912 64.1939C102.783 66.6475 100.458 66.9085 100.458 66.9085C100.458 66.9085 94.1226 66.9085 88.4372 63.6767C82.7518 60.4449 81.8455 60.0558 81.8455 56.8286C81.8455 53.6014 84.9491 47.1332 88.8263 49.9805L88.8217 49.9759Z"
        fill="#FF2406"
      />
      <path
        d="M84.8165 52.8181C84.8165 52.8181 73.5739 51.6554 74.7366 44.2855C75.8993 36.9155 67.3713 25.6729 49.9215 34.5901C35.4517 41.9875 32.7143 45.6496 29.1071 43.1456C27.7064 42.1752 25.7792 42.6055 24.9965 44.1161C24.2824 45.4894 23.4218 47.0869 22.5795 48.4877C21.7372 49.8884 22.2774 51.7469 23.7742 52.461C27.2944 54.1364 33.964 55.7981 42.2494 50.6163C54.6593 42.8618 68.7903 35.4507 69.3076 44.4136C69.8249 53.3766 73.0978 58.888 82.578 58.7186C82.578 58.7186 82.4041 55.0153 84.8165 52.8181Z"
        fill={`url(#${id2})`}
      />
      <path
        d="M59.4566 56.2098C59.8594 55.0517 61.1228 54.4383 62.2718 54.8777C64.7346 55.8161 68.74 56.4112 71.8939 52.4333C76.8057 46.2306 85.3337 40.8016 88.8264 49.9797L84.5601 58.6405C84.5601 58.6405 85.0774 52.4379 78.3575 58.8968C72.4158 64.6097 64.2493 64.4632 59.2186 61.8494C58.1932 61.3138 57.7858 60.055 58.2847 59.0113C58.6738 58.201 59.1224 57.194 59.4612 56.2144L59.4566 56.2098Z"
        fill="#FF2406"
      />
      <path
        d="M38.0566 49.147C37.8643 48.8907 43.9708 47.9797 44.9001 47.229C44.9001 47.229 48.4477 59.2955 65.9662 60.0829C65.9662 60.0829 66.7352 60.5361 66.9321 61.4562C67.1289 62.3763 71.0519 70.6892 73.0569 73.3442L71.6378 76.3654C71.6378 76.3654 63.4348 72.0762 52.6454 77.583C52.6454 77.583 49.1573 65.3471 39.4207 65.045C39.4207 65.045 42.0574 54.4525 38.052 49.147H38.0566Z"
        fill="#FCD21C"
      />
      <path
        d="M517.805 22.9655C517.805 22.9655 510.474 22.2324 508 22.2324L512.307 28.1889L509.1 36.8029L517.439 33.7788C517.439 33.7788 521.746 38.8189 522.754 40.0102C522.754 40.0102 523.945 34.0537 524.037 32.6792C524.037 32.6792 530.543 29.4718 531.643 28.6471L524.586 25.0732L523.578 16.001C523.762 15.9094 518.263 21.9575 517.805 22.9655Z"
        fill="#8686EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.171 45.0533C357.171 45.0533 359.677 46.0833 362.017 45.8243C362.017 45.8243 363 45.742 363.191 46.7425C363.383 47.7431 364.117 49.9382 364.524 50.7274C364.892 51.4873 365.521 50.9627 365.792 50.4229C366.063 49.8831 366.737 48.2853 367.034 47.4571C367.331 46.629 367.686 46.3959 368.511 46.4448C369.335 46.4936 370.577 46.4774 372.103 46.239C373.629 46.0006 372.872 45.0174 371.96 44.4136C371.048 43.8097 369.506 42.8069 369.506 42.8069C369.506 42.8069 368.675 42.2616 369.4 40.7625C370.085 39.2341 370.354 37.7707 370.354 37.7707C370.354 37.7707 370.75 36.8916 370.287 36.4309C369.824 35.9702 368.036 37.4931 367.181 38.1597C366.256 38.8371 365.96 38.9899 365.067 38.2766C364.174 37.5633 362.541 36.4326 362.541 36.4326C362.541 36.4326 362.038 35.9426 361.675 36.3545C361.313 36.7664 362.023 40.1734 362.023 40.1734C362.023 40.1734 362.321 40.9442 361.119 41.665C359.917 42.3858 357.707 43.4772 357.301 43.6116C356.797 43.7969 355.993 44.5622 357.171 45.0533Z"
        fill="#E16157"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.567 39.0234C206.567 39.0234 203.596 41.0665 202.143 43.8347C202.143 43.8347 201.483 45.021 200.229 44.4938C198.974 43.9665 196.003 43.1756 194.88 43.0438C193.758 42.912 193.89 43.9665 194.286 44.6256C194.682 45.2847 195.937 47.196 196.663 48.1846C197.389 49.1074 197.389 49.7005 196.729 50.5573C196.069 51.4141 195.21 52.7982 194.352 54.6436C193.494 56.4891 195.144 56.4232 196.465 55.83C197.786 55.2368 200.031 54.2482 200.031 54.2482C200.031 54.2482 201.219 53.7209 202.408 55.5664C203.596 57.4118 204.983 58.7959 204.983 58.7959C204.983 58.7959 205.709 59.8504 206.567 59.6527C207.426 59.4549 207.03 56.4232 206.964 54.9073C206.832 53.3914 206.898 52.93 208.35 52.4687C209.803 52.0073 212.246 51.0187 212.246 51.0187C212.246 51.0187 213.17 50.821 212.972 50.096C212.774 49.371 208.482 47.7233 208.482 47.7233C208.482 47.7233 207.426 47.5256 207.492 45.6801C207.558 43.8347 207.954 40.6052 208.086 40.012C208.218 39.4188 207.954 38.0348 206.567 39.0234Z"
        fill="#FCD21D"
      />
      <path
        d="M132.684 144.604C132.684 144.604 127.827 138.556 134.609 135.532C141.39 132.508 144.047 138.19 143.681 141.947C143.314 145.704 138.457 146.804 138.457 146.804C138.457 146.804 134.975 146.895 132.684 144.604Z"
        fill="#FCD21D"
      />
      <path
        d="M460.298 144.128C460.298 144.128 453.7 135.972 462.864 131.848C472.028 127.724 475.602 135.422 475.143 140.554C474.594 145.686 467.996 147.152 467.996 147.152C467.996 147.152 463.414 147.152 460.298 144.128Z"
        fill="#A2E3F9"
      />
      <path
        d="M469.05 58.7814C469.05 58.7814 466.026 55.0242 470.241 53.1915C474.457 51.3587 476.106 54.841 475.831 57.1319C475.556 59.4229 472.532 60.156 472.532 60.156C472.532 60.156 470.516 60.156 469.05 58.7814Z"
        fill="#A2E3F9"
      />
      <path
        d="M553.772 50.1911C553.772 50.1911 551.573 47.442 554.597 46.0674C557.621 44.6928 558.812 47.2587 558.721 48.9998C558.537 50.741 556.338 51.1992 556.338 51.1992C556.338 51.1992 554.78 51.1992 553.772 50.1911Z"
        fill="#FCD21D"
      />
      <path
        d="M261.99 52.4815C261.99 52.4815 259.15 48.9993 263.09 47.1665C267.031 45.4254 268.588 48.7244 268.405 50.9237C268.222 53.123 265.289 53.7645 265.289 53.7645C265.289 53.7645 263.273 53.7645 261.99 52.4815Z"
        fill="#8686EA"
      />
      <path
        d="M490.713 100.675C490.713 100.675 488.697 98.2003 491.446 97.009C494.195 95.8177 495.203 98.1086 495.111 99.5748C494.928 101.133 493.004 101.591 493.004 101.591C493.004 101.591 491.629 101.591 490.713 100.675Z"
        fill="white"
      />
      <path
        d="M139.673 86.5914C139.673 86.5914 137.749 84.2088 140.407 83.0175C143.064 81.8262 144.164 84.0256 143.98 85.5834C143.797 87.0496 141.873 87.5078 141.873 87.5078C141.873 87.5078 140.59 87.5078 139.673 86.5914Z"
        fill="white"
      />
      <path
        d="M558.53 138.354C558.53 138.354 556.346 147.92 548.386 145.408C540.426 142.895 540.765 135.966 535.619 139.236C530.474 142.506 529.513 141.489 526.333 140.258C523.153 139.026 520.701 140.741 519 141.424"
        stroke="#8686EA"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M444.075 42.6464C443.983 41.0886 443.892 39.5307 444.35 38.0645C445.266 35.0405 448.29 32.7495 451.497 32.8412C452.964 32.8412 454.338 33.2994 455.804 33.1161C457.912 32.9328 459.745 31.5582 460.753 29.7255C461.761 27.8927 462.128 25.785 461.944 23.6774"
        stroke="#8686EA"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00006 32.1774C8.00006 34.9774 11.6667 31.844 13 30.6774C15.5001 37.1774 23.5 34.1774 22 29.6774C20.8 26.0774 16.5 21.5107 14.5001 19.6774C5.50006 24.6774 3.50006 28.6774 6.00006 32.1774Z"
        fill="#2CD77E"
      />
      <defs>
        <linearGradient
          id={id1}
          x1="58.4232"
          y1="29.422"
          x2="58.4232"
          y2="107.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34DC85" />
          <stop offset="1" stopColor="#1FCD72" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1="31.3502"
          y1="38.8901"
          x2="72.7007"
          y2="60.7815"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2406" />
          <stop offset="1" stopColor="#FF705C" />
        </linearGradient>
      </defs>
    </svg>
  )
}
