import React, { SVGProps } from 'react'
export const VipNewYearActiveSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.152 11.6199C25.5884 10.8274 24.4116 10.8274 23.8481 11.6199L15.1463 23.8566C14.6728 24.5224 13.7351 24.6493 13.1017 24.1334L6.85103 19.0425C5.84721 18.2249 4.36377 19.0749 4.56145 20.3543L7.53571 39.6046C7.74866 40.9829 8.93494 42 10.3296 42H24.6261H25.425H39.6705C41.0651 42 42.2514 40.9829 42.4643 39.6046L45.4386 20.3543C45.6362 19.0749 44.1528 18.2249 43.149 19.0425L36.8984 24.1334C36.2649 24.6493 35.3272 24.5224 34.8538 23.8566L26.152 11.6199Z"
      fill={`url(#paint0_linear_2282_24623_active_${props.id})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6312 31.5824C23.6312 31.5824 23.9041 33.219 24.7551 34.3588C24.7551 34.3588 25.1115 34.8624 24.6652 35.2864C24.2156 35.7072 23.3421 36.7972 23.0788 37.2379C22.8155 37.6818 23.2683 37.8309 23.6087 37.7912C23.9491 37.7514 24.9606 37.599 25.4969 37.4863C26.0075 37.3604 26.2483 37.4764 26.4699 37.917C26.6947 38.3577 27.0929 38.9839 27.6805 39.6995C28.2682 40.4151 28.5508 39.7128 28.5572 39.0468C28.5636 38.3809 28.5829 37.2511 28.5829 37.2511C28.5829 37.2511 28.5925 36.6547 29.5623 36.5123C30.5321 36.3731 31.3542 36.0617 31.3542 36.0617C31.3542 36.0617 31.9162 35.9623 31.9965 35.5647C32.0767 35.1672 30.773 34.7464 30.1468 34.4813C29.5077 34.2428 29.3343 34.1268 29.4178 33.4311C29.5013 32.7353 29.5591 31.526 29.5591 31.526C29.5591 31.526 29.6522 31.102 29.3215 31.0456C28.9907 30.9893 27.52 32.4603 27.52 32.4603C27.52 32.4603 27.2438 32.8645 26.5052 32.4802C25.7699 32.0959 24.5335 31.3074 24.3184 31.1384C24.1 30.9694 23.4899 30.8137 23.6312 31.5824Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50545 33.2429C8.50545 33.2429 9.74549 33.2384 10.6885 32.7478C10.6885 32.7478 11.1028 32.5454 11.3619 32.9257C11.6182 33.3079 12.3171 34.0832 12.6105 34.3304C12.9063 34.5781 13.0707 34.262 13.0823 34.0062C13.0939 33.7503 13.1032 32.9859 13.0846 32.5768C13.0531 32.185 13.1676 32.0213 13.5193 31.9109C13.8713 31.7981 14.3811 31.5797 14.9796 31.2323C15.5781 30.8849 15.0941 30.592 14.6036 30.5072C14.1132 30.4224 13.2822 30.2723 13.2822 30.2723C13.2822 30.2723 12.8435 30.1934 12.8551 29.461C12.8691 28.7289 12.7382 28.0851 12.7382 28.0851C12.7382 28.0851 12.7325 27.6587 12.4489 27.5516C12.1653 27.4446 11.6982 28.3556 11.4274 28.7856C11.1745 29.2283 11.0682 29.3423 10.565 29.197C10.0619 29.0517 9.17686 28.8637 9.17686 28.8637C9.17686 28.8637 8.87526 28.744 8.79394 28.9812C8.71262 29.2184 9.62075 30.4801 9.62075 30.4801C9.62075 30.4801 9.88567 30.7324 9.51337 31.231C9.14145 31.7271 8.41115 32.5442 8.26064 32.6826C8.10975 32.8233 7.92151 33.2546 8.50545 33.2429Z"
      fill="white"
    />
    <path
      d="M39.5953 31.2855C39.5953 31.2855 37.598 33.2491 37 33.9663L39.8645 34.0858L41.7183 37L42.8126 33.8809C42.8126 33.8809 42.7241 33.898 43.3281 33.8809C43.3281 33.8809 43.6 32.5 43.6 32C43.6 32 44 29.7999 44.1 29L41.8857 29.7999L38.8718 28.0013C38.8837 27.9274 39.404 30.9212 39.5953 31.2855Z"
      fill={`url(#paint1_linear_2282_24623_active_${props.id})`}
    />
    <path
      d="M27.7302 24.1903C27.7302 24.1903 26.3987 25.4994 26 25.9775L27.9096 26.0572L29.1455 28L29.8751 25.9206C29.8751 25.9206 31.5973 25.8599 32 25.8485C32 25.8485 30.9794 24.7026 30.7163 24.4749C30.7163 24.4749 31.115 22.7219 31.1229 22.3803L29.2571 23.2L27.2478 22.0009C27.2558 21.9516 27.6027 23.9475 27.7302 24.1903Z"
      fill="white"
    />
    <path
      d="M19.4168 27.3951C19.4168 27.3951 18.2373 25.9124 19.8468 25.1983C21.4522 24.4843 22.0952 25.8578 21.9887 26.7903C21.8822 27.7228 20.7027 28 20.7027 28C20.7027 28 19.9492 27.9454 19.4168 27.3951Z"
      fill="white"
    />
    <path
      d="M24.2778 16.5968C24.2778 16.5968 23.4915 15.6083 24.5645 15.1322C25.6348 14.6562 26.0635 15.5719 25.9925 16.1935C25.9215 16.8152 25.1352 17 25.1352 17C25.1352 17 24.6328 16.9636 24.2778 16.5968Z"
      fill="white"
    />
    <path
      d="M6.27785 22.5968C6.27785 22.5968 5.49151 21.6083 6.56453 21.1322C7.63481 20.6562 8.06347 21.5719 7.99249 22.1935C7.9215 22.8152 7.13516 23 7.13516 23C7.13516 23 6.63279 22.9636 6.27785 22.5968Z"
      fill="white"
    />
    <path
      d="M42.2778 22.5968C42.2778 22.5968 41.4915 21.6083 42.5645 21.1322C43.6348 20.6562 44.0635 21.5719 43.9925 22.1935C43.9215 22.8152 43.1352 23 43.1352 23C43.1352 23 42.6328 22.9636 42.2778 22.5968Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id={`paint0_linear_2282_24623_active_${props.id}`}
        x1={4.48775}
        y1={10.473}
        x2={4.48775}
        y2={41.7297}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDE6E" />
        <stop offset={1} stopColor="#FFD062" />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_2282_24623_active_${props.id}`}
        x1={40.5}
        y1={31}
        x2={47}
        y2={31.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
)
