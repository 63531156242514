import React, { FC } from 'react'

export const VipDefaultSvg: FC<{
  size?: number
  color?: string
  styleDisplay?: string
}> = ({ size = 50, color = '#D8E2F0', styleDisplay = 'block' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 50 50"
    {...(styleDisplay && { style: { display: styleDisplay } })}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M23.848 11.62l-8.702 12.237c-.452.636-1.335.785-1.97.332-.026-.018-.05-.036-.074-.056l-6.251-5.09c-.605-.494-1.496-.402-1.989.203-.252.31-.362.713-.3 1.108l2.974 19.25C7.749 40.984 8.935 42 10.33 42H39.67c1.395 0 2.581-1.017 2.794-2.395l2.975-19.25c.119-.772-.41-1.494-1.182-1.613-.395-.062-.798.048-1.108.3l-6.25 5.091c-.606.493-1.496.402-1.99-.203l-.055-.073-8.702-12.237c-.452-.636-1.335-.785-1.971-.333-.129.092-.241.204-.333.333z"
    />
  </svg>
)
