import React, { FC } from 'react'

export const MambaLogoSvg: FC<{
  fill?: string
  width?: number
  height?: number
}> = ({ fill = '#F56323', width = 160, height = 56 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={'0 0 160 56'}
  >
    <g
      id="mamba_true"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group" transform="translate(0.000000, 8.000000)" fill={fill}>
        <path
          d="M95.1512758,34.4349869 L105.734596,34.4349869 L105.734596,16.6241081 C105.734596,6.52963615 95.1512758,5.8862021 86.8307512,9.80802869 C81.1085266,6.21032 74.1200811,7.81650426 67.3292109,10.256992 L67.3292109,34.4349869 L77.9125305,34.4349869 L77.9125305,16.2375675 C79.9752651,15.5329112 81.2402433,16.0454976 81.2402433,17.459612 L81.2402433,34.4349869 L91.8223204,34.4349869 L91.8223204,16.2375675 C93.8862976,15.5329112 95.1512758,16.0454976 95.1512758,17.459612 L95.1512758,34.4349869 Z"
          id="Fill-4"
        />
        <path
          d="M53.8290167,29.2416578 C53.8290167,29.2416578 50.1645562,30.4685041 50.1645562,26.7339456 C50.1645562,23.1098273 53.8290167,23.8372919 53.8290167,23.8372919 L53.8290167,29.2416578 Z M54.7858273,7.62299387 C50.9946205,7.62299387 46.0117496,8.76100783 42.8120257,10.4992401 L43.6768831,16.2121182 C46.2714553,15.2385641 53.8538689,13.6731947 53.8538689,17.1892737 L53.8538689,17.7762872 C47.1300997,17.7762872 40.8089364,19.8026243 40.8089364,26.8852006 C40.8089364,36.7119752 54.6528679,36.9724699 64.503047,33.2631206 L64.503047,16.8639553 C64.503047,9.7717755 60.7130828,7.62299387 54.7858273,7.62299387 L54.7858273,7.62299387 Z"
          id="Fill-9"
        />
        <path
          d="M27.8229348,34.4349869 L38.4050119,34.4349869 L38.4050119,16.6241081 C38.4050119,6.52963615 27.8229348,5.8862021 19.5011675,9.80802869 C13.7789429,6.21032 6.79049748,7.81650426 -0.000372783363,10.256992 L-0.000372783363,34.4349869 L10.5841895,34.4349869 L10.5841895,16.2375675 C12.6469241,15.5329112 13.9119023,16.0454976 13.9119023,17.459612 L13.9119023,34.4349869 L24.4927368,34.4349869 L24.4927368,16.2375675 C26.5579566,15.5329112 27.8229348,16.0454976 27.8229348,17.459612 L27.8229348,34.4349869 Z"
          id="Fill-6"
        />
        <path
          d="M119.541715,15.6265452 C123.332921,14.520943 124.003931,16.9938426 124.003931,21.6143233 C124.003931,26.2336036 123.332921,28.708904 119.541715,27.600901 L119.541715,15.6265452 Z M119.541715,-0.000120043666 L108.963365,-0.000120043666 L108.963365,33.2633607 C111.556695,34.3029389 116.878799,35.4121424 121.007996,35.4121424 C130.721488,35.4121424 134.316362,30.4639424 134.316362,20.7031919 C134.316362,12.3073378 129.859116,3.91388465 119.541715,9.31584965 L119.541715,-0.000120043666 Z"
          id="Fill-1"
        />
        <path
          d="M148.998404,29.2416578 C148.998404,29.2416578 144.860508,30.4685041 144.860508,26.7339456 C144.860508,23.1098273 148.998404,23.8372919 148.998404,23.8372919 L148.998404,29.2416578 Z M149.481779,7.62299387 C145.690573,7.62299387 140.707702,8.76100783 137.507978,10.4992401 L138.372835,16.2121182 C140.967407,15.2385641 148.549821,13.6731947 148.549821,17.1892737 L148.549821,17.7762872 C141.827294,17.7762872 135.506131,19.8026243 135.506131,26.8852006 C135.506131,36.7119752 149.347577,36.9724699 159.198999,33.2631206 L159.198999,16.8639553 C159.198999,9.7717755 155.407792,7.62299387 149.481779,7.62299387 L149.481779,7.62299387 Z"
          id="Fill-11"
        />
      </g>
    </g>
  </svg>
)
