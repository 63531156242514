import React, { FC } from 'react'
import styled from 'styled-components'

import { media, stepSize } from '../../presentational'
import { LogoIndex } from '../../presentational/Logo/LogoIndex'
import { isLoveMailru, noticePath } from 'common/constants'
import { RouterLink } from 'components/presentational/link'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { StyledVisuallyHiddenText } from 'components/presentational/VisuallyHidden'
import { indexPath } from 'components/paths'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useLocale } from 'hooks/useLocale'
import { useAuthorized } from 'hooks/useAuthorized'
import {
  ThirdPartyPlace,
  useThirdPartyUrl,
} from 'hooks/temrorary/useThirdPartyUrl'

const BaseLogoLink = styled(RouterLink)`
  display: flex;
  align-items: center;

  svg {
    width: 160px;
    height: 56px;
  }

  ${media.tablet`
    width: 100px;
  `};

  @media (max-width: ${stepSize}px) {
    svg {
      width: 91px;
      height: 32px;
    }
  }
`

export const LogoLink = styled(BaseLogoLink)``

export const LogoLinkMailRu = styled(BaseLogoLink)`
  display: flex;
  align-items: center;

  svg {
    width: 206px;
    height: 32px;
  }

  ${media.tablet`
      width: 180px;
  `};

  @media (max-width: ${stepSize}px) {
    svg {
      width: 180px;
      height: 28px;
    }
  }
`

const createLink = (
  profileBanned: boolean,
  trackBlocked: boolean,
  locale: string,
  withoutLink?: boolean,
  isAuthorized?: boolean
) => {
  if (withoutLink) {
    return undefined
  }

  if (trackBlocked) {
    return mergeAllUrls(noticePath, UniNoticeType.blocked_by_track_reasons)
  }

  if (profileBanned) {
    return mergeAllUrls(noticePath, UniNoticeType.user_banned)
  }

  if (isAuthorized) {
    return indexPath
  }

  return mergeAllUrls(locale, indexPath)
}

export const LayoutLogo: FC<{
  partnerId: number
  partnerSiteName: string
  withoutLink?: boolean
}> = ({ partnerId, partnerSiteName, withoutLink = false }) => {
  const locale = useLocale()
  const authorized = useAuthorized()

  const { profileBanned, trackBlocked } = useShallowEqualSelector(
    ({ errorReducer: { profileBanned, trackBlocked } }) => ({
      profileBanned,
      trackBlocked,
    })
  )

  const logoUrl = createLink(
    profileBanned,
    trackBlocked,
    locale,
    withoutLink,
    authorized
  )

  const thirdPartyUrl = useThirdPartyUrl(ThirdPartyPlace.logo)

  const content = (
    <>
      <LogoIndex />
      <StyledVisuallyHiddenText>{partnerSiteName}</StyledVisuallyHiddenText>
    </>
  )

  if (isLoveMailru(partnerId)) {
    return (
      <LogoLinkMailRu to={logoUrl} data-name={'link-main-action'}>
        {content}
      </LogoLinkMailRu>
    )
  }

  if (thirdPartyUrl) {
    return (
      <LogoLink
        to={thirdPartyUrl}
        isExternalLink={true}
        data-name={'link-main-action'}
      >
        {content}
      </LogoLink>
    )
  }

  return (
    <LogoLink to={logoUrl} data-name={'link-main-action'}>
      {content}
    </LogoLink>
  )
}
